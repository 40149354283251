import Section from '@/components/ui/atoms/Section'
import { Button } from '@/components/ui/atoms/button'
import { toast } from '@/components/ui/molecules/use-toast'
import { cn } from '@/lib/client'
import { useStore } from '@nanostores/react'
import { ArrowRightIcon } from 'lucide-react'
import { atom } from 'nanostores'
import { z } from 'zod'

const required_error = 'Obrigatório'

const loadingAtom = atom<boolean>(false)

const formAtom = atom<{ name: string; email: string; career: string }>({
  name: '',
  email: '',
  career: '',
})

const errorAtom = atom<{ [key: string]: string | undefined }>({})

const FormSchema = z.object({
  name: z.string({ required_error }).min(2, {
    message: 'Seu nome deve ter pelo menos 2 caracteres',
  }),
  email: z.string({ required_error }).email({
    message: 'Informe um e-mail válido',
  }),
  career: z.string({ required_error }).min(2, {
    message: 'Selecione uma carreira de interesse',
  }),
})

export default function Newsletter() {
  const $loading = useStore(loadingAtom)
  const $errors = useStore(errorAtom)
  const $form = useStore(formAtom)

  const careers = [
    { label: 'Fiscal', value: 'Fiscal' },
    { label: 'Policial', value: 'Policial' },
    { label: 'Tribunal', value: 'Tribunal' },
    { label: 'Controle', value: 'Controle' },
    { label: 'Administrativa', value: 'Administrativa' },
    { label: 'Legislativa', value: 'Legislativa' },
    { label: 'Agências Reguladoras', value: 'Agências Reguladoras' },
    { label: 'Bancária', value: 'Bancária' },
    { label: 'Militar', value: 'Militar' },
    { label: 'Educacional', value: 'Educacional' },
    { label: 'Saúde', value: 'Saúde' },
    { label: 'TI', value: 'TI' },
    {
      label: 'Exame de Suficiência - CFC',
      value: 'Exame de Suficiência - CFC',
    },
  ]

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target
    formAtom.set({ ...$form, [name]: value })
    errorAtom.set({ ...$errors, [name]: undefined })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      loadingAtom.set(true)
      FormSchema.parse($form)

      await fetch('/api/proxy/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            ...$form,
            type: 'newsletter',
          },
          null,
          2,
        ),
      })

      loadingAtom.set(false)

      toast({
        variant: 'success',
        title: 'Inscrição realizada com sucesso!',
        description: 'Fique atento ao seu e-mail para novidades.',
      })

      formAtom.set({ name: '', email: '', career: '' })
      errorAtom.set({})
    } catch (err) {
      loadingAtom.set(false)
      const errors: { [key: string]: string } = {}
      for (const error of err.errors) {
        errors[error.path[0]] = error.message
      }
      errorAtom.set(errors)
    }
  }

  return (
    <div className="relative isolate overflow-hidden bg-zinc-900 py-8 sm:py-12 lg:py-24">
      <Section id="newsletter">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Receba nossas novidades
            </h2>
            <p className="mt-4 text-lg leading-8 text-zinc-300">
              Fique por dentro dos novos editais e de todas as principais
              notícias do mundo dos concursos.
            </p>
          </div>
          <div className="flex flex-col items-start">
            <form onSubmit={handleSubmit} className="form space-y-4 w-full">
              <div className={cn('group', $errors.name && 'invalid')}>
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  placeholder="Nome"
                  className="block w-full bg-opacity-10 bg-white rounded-md border-0 placeholder:text-zinc-100 group-[.invalid]:border group-[.invalid]:border-rose-300 group-[.invalid]:text-rose-300 group-[.invalid]:placeholder:text-rose-300 px-3 py-1.5 text-white shadow-sm sm:text-sm sm:leading-6"
                  value={$form.name}
                  onChange={handleChange}
                />
                <p className="mt-2 ml-3 invisible group-[.invalid]:visible group-[.invalid]:text-rose-300 text-xs">
                  {$errors.name}
                </p>
              </div>
              <div className={cn('group', $errors.email && 'invalid')}>
                <input
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="email"
                  placeholder="E-mail"
                  className="block w-full bg-opacity-10 bg-white rounded-md border-0 placeholder:text-zinc-100 group-[.invalid]:border group-[.invalid]:border-rose-300 group-[.invalid]:text-rose-300 group-[.invalid]:placeholder:text-rose-300 px-3 py-1.5 text-white shadow-sm sm:text-sm sm:leading-6"
                  value={$form.email}
                  onChange={handleChange}
                />
                <p className="mt-2 ml-3 invisible group-[.invalid]:visible group-[.invalid]:text-rose-300 text-xs">
                  {$errors.email}
                </p>
              </div>
              <div className={cn('group', $errors.career && 'invalid')}>
                <select
                  id="career"
                  name="career"
                  className="bg-opacity-10 bg-white border-0 text-white text-sm rounded-lg focus:ring-orange-500 focus:border-orange-500 block w-full p-2.5 dark:bg-zinc-700 dark:border-zinc-600 dark:placeholder-zinc-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500  group-[.invalid]:border group-[.invalid]:border-rose-300 group-[.invalid]:text-rose-300 group-[.invalid]:placeholder:text-rose-300"
                  value={$form.career}
                  onChange={handleChange}
                >
                  <option value="">Carreira</option>
                  {careers.map(({ value }) => (
                    <option className="text-xs" key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <p className="mt-2 ml-3 invisible group-[.invalid]:visible group-[.invalid]:text-rose-300 text-xs">
                  {$errors.career}
                </p>
              </div>
              <div className="flex justify-end">
                <Button type="submit" disabled={$loading}>
                  Quero receber
                  <ArrowRightIcon className="ml-2 h-4 w-4" />
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Section>
      <div
        className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
        aria-hidden="true"
      >
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ffbb80] to-[#fca689] opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </div>
  )
}
